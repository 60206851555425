/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  var scrollHeight = 60;
  var lastScrollTop = 0;
  var isOnTop = false;
  var isOnBottom = true;
  var topPosition = 0;
  var scrollTopPosition = 0;

  // Home slideshow
  var SlideShow = {
    init: function (id, time, type) {
      var that = this;
      var $items = $(id).find('.content-block-list').find('.content-block-list-item');
      var itemsLength = $items.length;
      var index = 1;

      if (type === 'transitions') {
        var k = kontext(document.querySelector('.kontext'));
      }

      $items.eq(0).removeClass('hide');

      var timer = setInterval(function () {
        if (index === itemsLength) {
          index = 0;
        }

        $items.addClass('hide');
        $items.eq(index).removeClass('hide');

        $items.eq(index - 1).addClass('previous');
        $items.eq(index - 2).removeClass('previous');

        switch (type) {
          case 'number':
            that.numberShow($items, index);
            break;
          case 'transitions':
            k.next();
            break;
        }

        index++;
      }, time);
    },
    numberShow: function ($items, index) {
      var $el = $items.eq(index).find('.content-block-number');
      var value = Number($items.eq(index).find('.content-block-number').html());

      $({percentage: 0}).stop(true).animate({percentage: value}, {
        duration: 500,
        step: function () {
          var percentageVal = Math.round(this.percentage);
          $el.text(percentageVal);
        }
      }).promise().done(function () {
        $el.text(value);
      });
    }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        window.scrollTo(0, 0);

        this.addEventListener();
      },
      addEventListener: function () {
        $('#mobile-menu').on('click', function (e) {
          e.preventDefault();

          $(this).toggleClass('open');
          $('.nav-primary').toggle();
        });

        $(window).on('scrollHandler', function () {
          var st = $(window).scrollTop();

          if (st > scrollHeight) {
            $('.banner').addClass('sticky');
          } else {
            $('.banner').removeClass('sticky');
          }
        });

        $('a[href*="#"]')
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function (event) {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

              if (target.length) {
                event.preventDefault();

                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function () {
                  var $target = $(target);
                  $target.focus();

                  if ($target.is(':focus')) {
                    return false;
                  } else {
                    $target.attr('tabindex', '-1');
                    $target.focus();
                  }
                });
              }
            }
          });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      // JavaScript to be fired on the home page
      init: function () {
        scrollHeight = $('#home-content').offset().top - $('.banner').height();
        topPosition = scrollHeight - 50;
        scrollTopPosition = $('#home-content').css('margin-top');
        
        var whatslideshow = SlideShow.init('#what', 2800, 'number');
        var whoslideshow = SlideShow.init('#who', 4000);
        var theworkslideshow = SlideShow.init('#case', 8000);
        var ourClientsslideshow = SlideShow.init('#ourClients', 5000);
        var statisticslideshow = SlideShow.init('#statistic', 6500, 'transitions');

        this.addEventListener();
      },
      addEventListener: function () {
        var that = this;

        $(window).on('scrollHandler', function () {
          var st = $(this).scrollTop();

          if (st > lastScrollTop) {
            that.scrollDown();
          } else {
            that.scrollUp();
          }
          lastScrollTop = st;
        });
      },
      scrollUp: function () {
        var that = this;

        if ($(window).scrollTop() > 0 && $(window).scrollTop() < topPosition && isOnTop && window.innerWidth > 992) {
          isOnTop = false;
          that.disableScroll();

          $('html, body').stop(true, true).animate({scrollTop: 0}, 400, 'swing', function () {
            isOnBottom = true;
            that.enableScroll();
          });
        }
      },
      scrollDown: function () {
        var that = this;

        if ($(window).scrollTop() > 0 && $(window).scrollTop() < topPosition && isOnBottom && window.innerWidth > 992) {
          isOnBottom = false;
          that.disableScroll();

          $('html, body').stop(true, true).animate({scrollTop: scrollTopPosition}, 600, 'swing', function () {
            isOnTop = true;
            that.enableScroll();
          });
        }
      },
      preventDefault: function (e) {
        e = e || window.event;
        if (e.preventDefault) {
          e.preventDefault();
          e.returnValue = false;
        }
      },
      preventDefaultForScrollKeys: function (e) {
        if (keys[e.keyCode]) {
          this.preventDefault(e);
          return false;
        }
      },
      disableScroll: function () {
        if (window.addEventListener) {
            window.addEventListener('DOMMouseScroll', this.preventDefault, false);
        }
        window.onwheel = this.preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
        window.ontouchmove  = this.preventDefault; // mobile
        document.onkeydown  = this.preventDefaultForScrollKeys;
      },
      enableScroll: function () {
        if (window.removeEventListener) {
          window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        }
        window.onmousewheel = document.onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;  
      },
      // JavaScript to be fired on the home page, after the init JS
      finalize: function () {
      }
    },
    // Conquest page, note the change from page-template-template-conquest to page_template_template_conquest.
    'page_template_template_conquest': {
      init: function () {
        var btnNewSearch = $('#new-search-button');

        btnNewSearch.on('click', function (e) {
          e.preventDefault();
          $('#step-2a, .conquest-no-result').addClass('hidden');
          $('#step-1').removeClass('hidden');
        });

        $('#the_form_conquest').on('submit', function (e) {
          e.preventDefault();
          var that = this;
          var theCurrentYear = new Date().getFullYear();
          var wsUrl = 'http://buy.bbdo.fr' + $(this).attr('action') + theCurrentYear;
          var $btnSubmit = $(this).find('#indexSubmit');
          var $theFormConquest = $(this);
          var $inputBrandName = $(this).find('#indexInput');
          var brandNameToSearch = $inputBrandName.val();
          var currentIndex = 0;
          var toShow = "";

          if (brandNameToSearch !== '' && brandNameToSearch !== undefined && brandNameToSearch !== null) {
            $.get(wsUrl, function (data) {
              var obj = data[0].acf.conquest_brands;
              var result = null;

              for (var i = 0; i < obj.length; i++) {
                var brandsList = obj[i].conquest_brand_name;
                toShow = brandsList.split(',')[0].replace(/&AMP;/g, '&').replace(/&amp;/g, '&').replace(/&#039;/g, "'");

                var brandName = obj[i].conquest_brand_name.replace(/&#039;/g, "'").replace(' ', '').toLowerCase();
                var brandSearch = brandNameToSearch.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(' ', '').toLowerCase();

                // console.log(brandName + "|" + brandSearch);

                if (brandName.search(brandSearch) > -1) {
                  if (obj[i].conquest_brand_index > currentIndex) {
                    currentIndex = obj[i].conquest_brand_index;
                    result = obj[i];
                    break;
                  }
                }
              }

              if (result !== null) {
                // $('.conquest-result').find('.result-text-brand').text(result.conquest_brand_name.replace('&amp;', '&').replace('&quot;', '"'));
                $('.conquest-result').find('.result-text-brand').text(toShow);
                $('.conquest-result').find('.result-index').text(Number(result.conquest_brand_index).toFixed(2));
                $('.conquest-result').removeClass('hidden');

                $('#step-1').addClass('hidden');
                $('#step-2b').removeClass('hidden');
              } else {
                $('.conquest-no-result').find('.result-text-brandname').text(brandNameToSearch);
                $('.conquest-no-result').find('.result-text-date').text(theCurrentYear);
                $('.conquest-no-result').removeClass('hidden');

                $('#step-1').addClass('hidden');
                $('#step-2a').find('.conquest-title-date').text(theCurrentYear + 1);
                $('#step-2a').removeClass('hidden');
              }
            })
            .done(function () {
              console.log('second success');
            })
            .fail(function () {
              console.log('error');
            })
            .always(function () {
              console.log('finished');
            });
          }
        });
      }
    },
    'post_template_default': {
      init: function () {
        this.addEventListener();
      },
      addEventListener: function () {
        var that = this;

        $(window).on('scrollHandler', function () {
          var parallaxPos = 65 - Math.round($(this).scrollTop() * 65 / 600);
          if (parallaxPos > 0 && parallaxPos < 100) {
            $('.page-header-image').find('.bg-block').css({'top': parallaxPos + '%'});
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    scrollHandler: function () {
      $(window).trigger('scrollHandler');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(document).on('scroll', UTIL.scrollHandler);
})(jQuery); // Fully reference jQuery after this point.
